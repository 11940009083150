$zaux-token-colors:(
  default: (
    white: #FFFFFF,
    black: #333333,
    red: #d9534f,
    green: #5cb85c,
    blue: #337ab7,
    magenta: #FF00FF,
    orange: #f0ad4e,
    warning: #f0ad4e,
    error: #d9534f
  ),
  app: (
    theme: #005AAA
  ),
  set1: (
    darkgrey: #646464,
    darkblue: #002D74,
    blue1: #005AAA,
    lightblue: #B8CEED,
    red1: #E15622,
    orange1: #F6A100,
    white1: #FFFFFF,
    lightorange: #FBCC98,
    whiteblue: #F4F6F8
  )
);
