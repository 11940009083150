@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card4");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {

  &--theme-light {
  background-color: tokenColor(set1,white1);

  & h4, p {
    color: tokenColor(set1, darkblue);
  }

}

&--theme-dark {
  color: tokenColor(set1, white);
}

}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

