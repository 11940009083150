@charset "UTF-8";

@import "../../assets/scss/components/tokens/introsection.style";

$component: componentClass("introsection");
$cmp-tokens: ();

@if (variable-exists(zaux-infocard-style-tokens)){
    $cmp-tokens : $zaux-infocard-style-tokens;
}

.#{$component} {
    overflow: hidden;
    &__inner {
        margin-top: 120px;
        height: calc( 100vh / 2);
        @include media-breakpoint-down(md){
          margin-top: 72px;
        }
        &--scroll {
          margin-top: 70px;
          @include media-breakpoint-down(md){
              margin-top: 0;
          }
        }
    }

    &__content {
      position: relative;
      &--nav {
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
        & ul {
          display: flex;
          padding-left: 0;
          white-space: nowrap;
          & li {
            position: relative;
            text-transform: uppercase;
            margin-right: 64px;
            margin-bottom: 0 !important;
            padding-bottom: 8px;
            letter-spacing: 0.008em;
            &:last-child {
              margin-right: 12px;
            }
            &:before {
              content: "";
            }
            @include media-breakpoint-down(md){
              margin-right: 32px;
            }
            &.over-limit {
              margin-right: 48px;
              @include media-breakpoint-down(md){
                margin-right: 32px;
              }
            }
          }
        }
      }
      &--row {
        margin-left: calc( 100vw /2 - 1288px / 2 - 96px);
        margin-right: 0;
        @include media-breakpoint-down(md){
          margin-right: 0;
        }
      }
    }


}

@media screen and (max-width: 1480px) {
  & {
  .#{$component}__content--row {
    margin-left: 0;
  }
  }
}
