@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card1");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {


  &--theme--dark {

    & h4 {
      color: tokenColor(set1, white1);
    }

  & .#{$component}__content {
    background-color: tokenColor(set1, darkblue);
    transition: background-color $zaux-transition-1;
    &--info {
      & p {
        color: tokenColor(set1, lightblue) !important;
      }
    }
  }

  &:hover {
    & .#{$component}__content {
      background-color: tokenColor(set1, blue1);
      transition: background-color $zaux-transition-1;
    }

  }

}

}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

