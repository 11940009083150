@charset "UTF-8";

$component: componentClass("sectionhistory");

.#{$component} {

 &__content {
  background-color: tokenColor(set1, whiteblue);
 }

}
