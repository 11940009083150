@charset "UTF-8";

@import "../../assets/scss/components/tokens/newsletterbox1.style";

$component: componentClass("newsletterbox");
$cmp-tokens: ();

@if (variable-exists(zaux-newsletterbox1-style-tokens)){
    $cmp-tokens : $zaux-newsletterbox1-style-tokens;
}

.#{$component} {

  &__logo {
    transform: translateY(-30px);
    transform: translateY(-68px);
  }

  &__content {
    z-index: 2;
  }
  &__form {
    max-width: 628px;
    margin: auto;

  }

    .wpcf7-spinner{
        top:12px;
    }
    .wpcf7-not-valid-tip{
        display:none !important;
    }
}
