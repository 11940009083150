@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card2");

.#{$component} {

  // &__inner {
  // }

  &__player {
    z-index: 2;
    left: 32px;
    right: 32px;
    bottom: 32px;
    // padding-bottom: 64px;
    &--name {
      word-break: break-all;
    }
    &--firstname {
      letter-spacing: -0.024em;
      display: block;
      @include media-breakpoint-down(md){
        line-height: 24px;
      }
    }
    &--lastname {
      display: block;
      @include media-breakpoint-down(md){
        line-height: 32px;
      }
    }

    &--info {
      @include media-breakpoint-down(md){
        opacity: 1;
      }
      opacity: 0;
      transition: opacity $zaux-transition-1;
      & span {
        text-transform: uppercase;
        font-family: $zaux-font3;
        letter-spacing: 0.008em;
        display: block;
      }
    }

  }

  &__role {
    & span {
      letter-spacing: 0.008em;
      line-height: 1.5rem;
      @include media-breakpoint-down(md) {
        line-height: 1rem;
      }
    }
  }
  @include media-breakpoint-up(md){
    &:hover {
      & .#{$component}__player--info {
        opacity: 1;
        transition: opacity $zaux-transition-1
      }
    }
  }

  &--history {
    opacity: 0.5;
    transition: all .4s ease;
    &.active {
      transform: translateY(32px);
      opacity: 1;
      transition: all .4s ease;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__data {
    bottom: 32px;
    left: 32px;
    right: 32px;
    @include media-breakpoint-down(md) {
      bottom: 16px;
      left: 16px;
      right: 16px;
    }

  }

}
