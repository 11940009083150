@charset "UTF-8";

@import "../../assets/scss/components/tokens/displayblock1.style";

$component: componentClass("displayblock2");
$cmp-tokens: ();

@if (variable-exists(zaux-displayblock2-style-tokens)){
    $cmp-tokens : $zaux-displayblock2-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


 .#{$component}{

  &--post {
    position: relative;
    &::after {
      content: '';
      height: calc( 100vh / 2 );
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: tokenColor(set1, darkblue);
    }
    & .#{$component}__inner {
      position: relative;
      &::after {
        content: '';
      height: calc( 100vh / 2 );
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to bottom, #002D7400, #265AA4);
      z-index: 1;
      }
    }
  }

  &__content {
    &--title {
      color: tokenColor(set1, white);
    }
    &--info {
      color: tokenColor(set1,lightblue);
      transition: color $zaux-transition-1;
      & a {
        &:hover {
          color: tokenColor(set1, orange1);
          transition: color $zaux-transition-1;
        }
      }
    }
  }

}
