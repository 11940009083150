@charset "UTF-8";

@import "../../assets/scss/components/tokens/btn.style";

$component: componentClass("btn");
$cmp-tokens: ();

@if ($zaux-btn-style-tokens){
    $cmp-tokens : $zaux-btn-style-tokens;
}

$c-btn-hover-color-shift-factor: 10%;

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

@mixin button-primary-light1 {
    transition: background-color $zaux-transition-1, color $zaux-transition-1;
    background-color:getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor('set1','white1'), 'theme-light1');
    color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor', tokenColor('set1', 'blue'), 'theme-light1');
    &:hover {
      background-color:getCmpToken($cmp-tokens, 'color-cmp-bg-h', tokenColor('set1', lightblue), 'theme-light1');
      color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor-h', tokenColor('set1', white1), 'theme-light1') !important;
      transition: background-color $zaux-transition-1, color $zaux-transition-1;
    }
}

@mixin button-secondary-light1 {
  transition: color $zaux-transition-1;
  color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor2', tokenColor('set1', white1), 'theme-light1') ;
  &:hover {
    color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor2-h', tokenColor('set1', lightblue), 'theme-light1') !important;
    transition: color $zaux-transition-1;
  }
}


////////////////////////////////////////////////////
// Theme: dark1
////////////////////////////////////////////////////
///
@mixin button-primary-dark1 {
  transition: background-color $zaux-transition-1, color $zaux-transition-1;
  background-color:getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor('set1','darkblue'), 'theme-dark1');
  color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor', tokenColor('set1', 'white1'), 'theme-dark1');
  &:hover {
    background-color:getCmpToken($cmp-tokens, 'color-cmp-bg-h', tokenColor('set1','lightblue'), 'theme-dark1');
    color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor-h', tokenColor('set1', darkblue), 'theme-dark1') !important;
    transition: background-color $zaux-transition-1, color $zaux-transition-1;
  }

}

@mixin button-primary-dark2 {
  transition: background-color $zaux-transition-1, color $zaux-transition-1;
  background-color:getCmpToken($cmp-tokens, 'color-cmp-bg-d2', tokenColor('set1','blue1'), 'theme-dark1');
  color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor-d2', tokenColor('set1', 'white1'), 'theme-dark1');
  &:hover {
    background-color:getCmpToken($cmp-tokens, 'color-cmp-bg--d2-h', tokenColor('set1', lightblue), 'theme-dark1');
    color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor-d2-h', tokenColor('set1', darkblue), 'theme-dark1') !important;
    transition: background-color $zaux-transition-1, color $zaux-transition-1;
  }

}

@mixin button-secondary-dark1 {
  transition: background-color $zaux-transition-1, color $zaux-transition-1;
  color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor2', tokenColor('set1', darkblue), 'theme-dark1');
  &:hover {
    color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor2-h', tokenColor('set1', lightblue), 'theme-dark1') !important;
    transition: background-color $zaux-transition-1, color $zaux-transition-1;
  }
  & i {
    color: getCmpToken($cmp-tokens, 'color-cmp-icon', tokenColor('set1', lightblue), 'theme-dark1');
  }
}

.#{$component} {
    &--primary-light {
        @include button-primary-light1;
    }
    &--secondary-light {
      @include button-secondary-light1;
  }
}

.#{$component} {
  &--primary-dark {
      @include button-primary-dark1;
  }
  &--primary-dark2 {
    @include button-primary-dark2;
}
  &--secondary-dark {
    @include button-secondary-dark1;
}

}


