@charset "UTF-8";


@import "../../assets/scss/components/tokens/btn.style";

$component: componentClass("btn");
$cmp-tokens: ();

@if ($zaux-btn-style-tokens){
    $cmp-tokens : $zaux-btn-style-tokens;
}

@mixin c-btn-size($px) {
  text-align: center;

  .#{$component} {
    &__inner {
      font-size: rem($px);
      border: 1px solid transparent;
      height: 1em;
      border-radius: 0.5em;
      display: block;
    }
  }
}

[class*="#{$component}--"] {
  // Commented in favor of Bootstrap's `$line-height-base: 0;` (see src/assets/scss/abstracts/_variables.scss)
  font-size: 0;
  line-height: 0;

  &,
  &:link,
  &:visited,
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.#{$component} {

  display:inline-block;

  &1 {
  font-weight: bold;
  font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(6), 'layout');
  text-transform: uppercase;
  padding: 0 32px;
  letter-spacing: 0.2em;
  border-radius: 4px;

  //Sizes

  //Small
  &--size-s{
    font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(5), 'layout');
    // line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(3), 'layout');
    line-height: 36px;
    padding: 0 16px;
  }

  //Default and medium
  &--size-m {
    // font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(6), 'layout');
    // line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(6), 'layout');
    font-size: 20px;
    line-height: 56px;
    &-res1 {
      @include media-breakpoint-down(md) {
        // font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(3), 'layout');
      // line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(3), 'layout');
      font-size: 16px;
      line-height: 48px;
      padding: 0 16px;
      }
    }
  }

  //Large
  &--size-l{
    font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(9), 'layout');
    line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(9), 'layout');
    // line-height: 24px;
    &-res1 {
      @include media-breakpoint-down(md) {
        font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(6), 'layout');
      line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(6), 'layout');
      // line-height: 16px;
      }
    }
  }

  &--size-xl{
    font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(14), 'layout');
    line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(14), 'layout');
    // line-height: 48px;
    &-res1 {
      @include media-breakpoint-down(md) {
        font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(9), 'layout');
      line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(9), 'layout');
      // line-height: 24px;
      }
    }
  }

  &--has-icon {
    position: relative;
    overflow: hidden;
    .#{$component}1__label {
      font-size: getCmpToken($cmp-tokens, 'fs-btn', 14px, 'layout');
      line-height: getCmpToken($cmp-tokens, 'lh-btn', 14px, 'layout');
      padding-right:getCmpToken($cmp-tokens, 'spacer-label-pl', tokenSpacer(1), 'layout');
    }
    .#{$component-prefix}icon {
      &-size-sm{
        font-size:getCmpToken($cmp-tokens, 'fs-icon-size-sm', tokenIconSize(2), 'layout');
      }
      &-size-md{
        font-size:getCmpToken($cmp-tokens, 'fs-icon-size-md', tokenIconSize(3), 'layout');
        &-res1 {
          @include media-breakpoint-down(md) {
            font-size:getCmpToken($cmp-tokens, 'fs-icon-size-md', tokenIconSize(2), 'layout');
          }
        }
      }
      &-size-lg{
        font-size:getCmpToken($cmp-tokens, 'fs-icon-size-lg', tokenIconSize(4), 'layout');
        &-res1 {
          @include media-breakpoint-down(md) {
            font-size:getCmpToken($cmp-tokens, 'fs-icon-size-md', tokenIconSize(3), 'layout');
          }
        }
      }
      &-size-xl{
        font-size:getCmpToken($cmp-tokens, 'fs-icon-size-xl', tokenIconSize(6), 'layout');
        &-res1 {
          @include media-breakpoint-down(md) {
            font-size:getCmpToken($cmp-tokens, 'fs-icon-size-md', tokenIconSize(4), 'layout');
          }
        }
      }

    }
  }

}

&2 {
  font-weight: getCmpToken($cmp-tokens, 'fw-btn', $zaux-typo-fw-semibold, 'layout');
  font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(6), 'layout');
  line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(9), 'layout');
  position: relative;
  text-transform: uppercase;

  //Sizes

  //Small
  &--size-s{
    font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(6), 'layout');
    line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(3), 'layout');
  }

  //Default and medium
  &--size-m{
    font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(6), 'layout');
    line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(9), 'layout');
    &-res1 {
      @include media-breakpoint-down(md) {
      font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(3), 'layout');
      line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(6), 'layout');
    }
  }
  }

  //Large
  &--size-l{
    font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(9), 'layout');
    line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(9), 'layout');
  }

  &--has-icon{
    // padding: getCmpToken($cmp-tokens, 'spacer-btn-py', tokenSpacer(4), 'layout');
    .#{$component}2__label{
      font-size: getCmpToken($cmp-tokens, 'fs-btn', 14px, 'layout');
      line-height: getCmpToken($cmp-tokens, 'lh-btn', 14px, 'layout');
      padding-left:getCmpToken($cmp-tokens, 'spacer-label-pl', tokenSpacer(1), 'layout');
    }
    .#{$component-prefix}icon {
      &-size-sm{
        font-size:getCmpToken($cmp-tokens, 'fs-icon-size-sm', tokenIconSize(2), 'layout');
      }
      &-size-md{
        font-size:getCmpToken($cmp-tokens, 'fs-icon-size-md', tokenIconSize(3), 'layout');
        &-res1 {
          @include media-breakpoint-down(md) {
            font-size:getCmpToken($cmp-tokens, 'fs-icon-size-md', tokenIconSize(2), 'layout');
          }
        }
      }
      &-size-lg{
        font-size:getCmpToken($cmp-tokens, 'fs-icon-size-lg', tokenIconSize(4), 'layout');
      }
      &-size-xl{
        font-size:getCmpToken($cmp-tokens, 'fs-icon-size-xl', tokenIconSize(6), 'layout');
      }
    }
  }

}

}
