@charset "UTF-8";

// @import "../../assets/scss/components/tokens/showcase1.style";

$component: componentClass("showcase");
$cmp-tokens: ();

// @if ($zaux-showcase1-style-tokens){
//     $cmp-tokens : $zaux-showcase1-style-tokens;
// }

.#{$component} {
  &__body {
    overflow: hidden;
    &--scrolling {
      & .swiper-wrapper {
        -webkit-transition-timing-function:linear!important;
        -o-transition-timing-function:linear!important;
        transition-timing-function:linear!important;
      }
      & .swiper-slide {
        & picture {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          & img {
            max-height: 48px;
            width: auto;
            max-width: 234px;
            @include media-breakpoint-down(lg){
              max-height: 40px;
              max-width: 208px;
            }
            @include media-breakpoint-down(md){
              max-height: 32px;
              max-width: 100%;
            }
          }
        }
      }

    }
  }
}

