@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("multimediacard");

.#{$component} {

 &__content {
  padding: 32px 32px 16px 32px;
  @include media-breakpoint-down(md) {
    padding: 24px 24px 16px 24px;
  }
  // height: 50%;
  & h4 {
    letter-spacing: -0.005em;
    @include media-breakpoint-down(md){
      line-height: 24px;
    }
  }
 }

 &__img {
  & picture {
    &::after {
      mix-blend-mode: normal;
      position: absolute;
      top: 0;
      left: 0;
      content: " ";
      width: 100%;
      height: 100%;
      display: block;
      z-index: 1;
      background-color: #002D7440;
      border-radius: 4px;
    }
    & img {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  &--label {
    position: absolute;
    left: 32px;
    bottom: 32px;
    z-index: 1;
    & i {
      vertical-align: middle;
    }
  }
}


}
