@charset "UTF-8";

$component: componentClass("footer");
$cmp-tokens: ();

@if ($zaux-footer-style-tokens){
    $cmp-tokens : $zaux-footer-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {


    &__inner {
      background-color: #002D74D9;
      color: getCmpToken($cmp-tokens, 'color-cmp-text', tokenColor('default', 'white'), 'theme-light1');
    }

    &__menu {

      &--col {
        @include media-breakpoint-up(md){
          border-top: 2px solid tokenColor(set1, orange1);
          &--haschild {
            & .#{$component}__menu--col--label {
              &:hover {
                color: white !important;
                cursor: unset;
              }
            }
          }

        }

       @include media-breakpoint-down(md) {
        &--haschild {
          & .#{$component}__menu--col--label {
            cursor: pointer;
            padding: 0 16px;
            background-image: url('../../assets/icon/icozaux1/SVG/arrow-down-y.svg');

            background-repeat: no-repeat;
            background-size: 8px;
            background-position: top 10px right;
          }
        }
        &.open {
           & .#{$component}__menu--col--show {
              background-color: tokenColor(set1, darkblue);
                }
              }
              &--item {
                &--label {
                  color: tokenColor(set1, white1);
                  background-image: url('../../assets/icon/icozaux1/SVG/arrow-left-w.svg');
                  padding: 0 12px;
                  background-repeat: no-repeat;
                  background-size: 12px;
                  background-position: top 2px left -4px;
                }
              }
        }
      }
    }
    &__info {
      & p a {
        color: tokenColor(set1, white1) !important;
      }
    }
    &__social{
        &-item{
            .#{$component-prefix}icon{
                color: getCmpToken($cmp-tokens, 'color-social-item', tokenColor('set1', 'white1'), 'theme-light1');
                transition: color $zaux-transition-1;
            }
            &:hover {
              .#{$component-prefix}icon{
                color: tokenColor(set1, orange1);
                transition: color $zaux-transition-1;
            }
            }
        }
    }
    &__credits {
        background-color: getCmpToken($cmp-tokens, 'color-footer-credits-bg', tokenColor('set1', darkblue ), 'theme-light1');
        color: getCmpToken($cmp-tokens, 'color-footer-credits-text', tokenColor('set1', darkblue ), 'theme-light1');
    }

}
