@charset "UTF-8";

@import "../../assets/scss/components/tokens/menu.style";

$component: componentClass("menu");
$cmp-tokens: ();

@if (variable-exists(zaux-header-style-tokens)){
    $cmp-tokens : $zaux-header-style-tokens;
}

.zaux-menu-active {
  overflow: hidden;
}

.#{$component} {
  &__inner {
    height: 100%;
    width: 100%;
  }
  &__nav {
    height: 100%;
    width: 100%;
    &--list {
      display: flex;
      justify-content: space-between;
      height: 100%;
      white-space: nowrap;
    }
    &--item {
      position: relative;
      font-size: 16px;
      @include media-breakpoint-down(xl){
        font-size: 14px;
      }
      & > a {
        padding-left: 16px;
      }
      &--haschild {
        & > a {
          padding: 0 16px;
        }
        &:first-child {
          @include media-breakpoint-up(xl){
            & > a {
              padding-left: 0;
            }
          }
        }

      }

      &--show {
        width: 258px;
        padding: 32px;
        display: none;
        position: absolute;
        top: 68px;
      }

      @include media-breakpoint-up(lg){
        &:hover {
        & .#{$component}__nav--item--show {
          display: block;
        }
      }
    }

      & .c-nav__item {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        &--label {
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.008em;
          font-weight: 400;
        }
        &--icon {
            font-size: 12px;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    display: none;
    &--var1.open {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 68px;
      z-index: 90;

      & .#{$component}__inner {
        overflow-y: auto;
      }

      & .#{$component}__nav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &--list {
          flex-direction: column;
          height: auto;
          width: 100%;
          flex-grow: 1;
          justify-content: center;
        }
        &--item {
          text-align: center;
          padding-top: 24px;

          &--label {
            padding-bottom: 12px;
          }

          &--show {
            width: 100%;
            top: 0;
            left: 0;
            text-align: center;

            display: block;
            height: 0;
            opacity: 0;
            padding: 0;
            transition: all 0.4s ease-out;
          }
          &.open {
          & .#{$component}__nav--item--show {
            // display: block;
            height: auto;
            opacity: 1;
            padding: 24px;
            transition: all 0.4s ease-in;
          }
        }
        }

      }
    }
  }

}

