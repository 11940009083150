@charset "UTF-8";

@import "../../assets/scss/components/tokens/infocard.style";

$component: componentClass("infocard");
$cmp-tokens: ();

@if (variable-exists(zaux-infocard-style-tokens)){
    $cmp-tokens : $zaux-infocard-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.#{$component}{
  & h6 {
    color: tokenColor(set1,blue1);
  }
  &:hover {
    & h6 {
      color: tokenColor(set1,lightblue);
    }
  }
}
