@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card2");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {


  &--theme--dark {

  & h4, p, span {
    color: tokenColor(set1, white1);
  }

  & picture {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: " ";
      width: 100%;
      height: 100%;
      display: block;
      background: linear-gradient(to bottom, #B9CFEC00 , #163475F5 95%, #0F2E70 100% );
      transition: background-color $zaux-transition-1;
      border-radius: 4px;
    }
  }


  &:hover {
    & picture {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: " ";
        width: 100%;
        height: 100%;
        display: block;
        background-color: #005AAABF; //opacity 75%
        transition: background-color $zaux-transition-1;
      }
    }
  }

}

}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

