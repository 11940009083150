@charset "UTF-8";

@import "../../assets/scss/components/tokens/displayblock1.style";

$component: componentClass("displayblock3");
$cmp-tokens: ();

@if (variable-exists(zaux-displayblock2-style-tokens)){
    $cmp-tokens : $zaux-displayblock2-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


 .#{$component}{

  &__content {
    &--img {
      background-color: #F4F6F8;
    }
  }

}
