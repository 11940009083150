@charset "UTF-8";

$component: componentClass("sectionhistory");

.#{$component} {

&__content {
  display: none;
  &.open {
    display: block;
  }
}

}
