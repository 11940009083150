@charset "UTF-8";

// @import "../../assets/scss/components/tokens/inforcard.style";

$component: componentClass("infocard");
$cmp-tokens: ();

// @if (variable-exists(zaux-infocard-style-tokens)){
//     $cmp-tokens : $zaux-infocard-style-tokens;
// }

.#{$component} {
    &__title{
        line-height: 24px;
    }
}
