@charset "UTF-8";

@import "../../assets/scss/components/base";
@import "../../assets/scss/components/tokens/menu.style";

$component: componentClass("menu");
$cmp-tokens: ();

@if (variable-exists(zaux-header-style-tokens)){
    $cmp-tokens : $zaux-header-style-tokens;
}

.#{$component} {

    &__nav--item {
      @include media-breakpoint-up(lg) {
        &:hover {
          & .#{$component}__nav--item--label {
            color: tokenColor(set1, lightblue);
          }
        }
      }
      @include media-breakpoint-down(lg){
        & .#{$component}__nav--item--label:hover {
          color: tokenColor(set1, lightblue);
        }
      }
        &--haschild {
          & > a {
          background-image: url('../../assets/icon/icozaux1/SVG/arrow-down-y.svg');
          background-repeat: no-repeat;
          background-size: 8px;
          background-position: top 5px right;
          }
          &:hover {
          @include media-breakpoint-up(lg) {
            position: relative;
              &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 3px;
                left: 0;
                bottom: 0;
                background-color: tokenColor(set1,orange1);
              }
          }
        }
      }

      &--show {
        background-color: tokenColor(set1, lightblue);
      }
      & .c-nav__item--label {
        color: tokenColor(set1, darkblue);
        background-image: url('../../assets/icon/icozaux1/SVG/arrow-left-w.svg');
        padding: 0 12px;
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: top 2px left -4px;
        &:hover {
          color: tokenColor(set1, white1);
        }

      }
      & .c-nav__item--icon {
        color: tokenColor(set1, white1);
      }
    }

    @include media-breakpoint-down(lg) {

      &--var1.open {

        & .#{$component}__inner {
          background-color: tokenColor(set1, white1);

        }
        & .#{$component}__nav {
          &--item {
            &--show {
              @include media-breakpoint-down(lg) {
                position: relative;
                  &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    left: 0;
                    top: -3px;
                    background-color: tokenColor(set1,orange1);
                  }
              }
            }
          }
        }
      }
    }
}
