$zaux-token-typo:(
  base-font-size: 16px,
  breakpoints: (sm, md, lg, xl),
  sizes: (
    1: (
      fs: 8px,
      lh: 12px
    ),
    2: (
      fs: 10px,
      lh: 12px
    ),
    3: (
      fs: 12px,
      lh: 16px
    ),
    4: (
      fs: 13px,
      lh: 18px
    ),
    5: (
      fs: 14px,
      lh: 22px
    ),
    6: (
      fs: 16px,
      lh: 24px
    ),
    7: (
      fs: 18px,
      lh: 26px
    ),
    8: (
      fs: 22px,
      lh: 30px
    ),
    9: (
      fs: 24px,
      lh: 32px
    ),
    12: (
      fs: 32px,
      lh: 36px
    ),
    13: (
      fs: 42px,
      lh: 36px
    ),
    14: (
      fs: 48px,
      lh: 52px
    ),
    16: (
      fs: 64px,
      lh: 52px
    ),
    18: (
      fs: 80px,
      lh: 80px
    ),
    22: (
      fs: 125px,
      lh: 52px
    ),
    24: (
      fs: 160px,
      lh: 107px
    )
  )
);
