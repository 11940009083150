@charset "UTF-8";

@import "../../assets/scss/components/tokens/displayblock1.style";

$component: componentClass("displayblock2");
$cmp-tokens: ();

@if (variable-exists(zaux-displayblock2-style-tokens)){
    $cmp-tokens : $zaux-displayblock2-style-tokens;
}

.#{$component} {
  &--post {
    margin-top: 120px;
    @include media-breakpoint-down(md){
      margin-top: 72px;
    }
  }

  &__content--col2 {
    padding-top: calc( 100vh / 2 - 144px );
    @include media-breakpoint-down(md){
      padding-top: 0;
    }
  }

}
