@charset "UTF-8";

//////////////////////////////////////////////////
// CONTAINER - HELPERS
//////////////////////////////////////////////////

.container-l {
    padding: 0 96px;
    @include media-breakpoint-down(md) {
      padding: 0 16px;
    }
}
.container-left {
  padding-left: 96px;
  @include media-breakpoint-down(md) {
    padding-left: 16px;
  }
}
.container-slider {
  padding-left: 96px;
  @include media-breakpoint-down(md) {
    padding-left: 16px;
  }
}

