@charset "UTF-8";

@import "../../assets/scss/components/base";
@import "../../assets/scss/components/tokens/header.style";

$component: componentClass("header");
$cmp-tokens: ();

@if (variable-exists(zaux-header-style-tokens)){
    $cmp-tokens : $zaux-header-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.#{$component}{
  &__match {
    background-color: tokenColor(set1, darkblue);
    & p {
      color: tokenColor(set1,white1);
    }
    &--social--item {
      & i {
        color: tokenColor(set1,white1);
        transition: color $zaux-transition-1;
        &:hover {
          color: tokenColor(set1,orange1);
          transition: color $zaux-transition-1;
      }
      }
    }
    @include media-breakpoint-down(md) {
      background-color: tokenColor(set1, white1);
      & p {
        color: tokenColor(set1,darkblue);
      }
    &--social--item {
      & i {
        color: tokenColor(set1,darkblue);
      //   &:hover {
      //   color: tokenColor(set1,lightblue);
      // }
      }
    }
    }
    &--data {
      & i {
        color: tokenColor(set1,white1);
        @include media-breakpoint-down(md) {
          color: tokenColor(set1,darkblue);
        }
        &:hover {
          color: tokenColor(set1,white1);
          @include media-breakpoint-down(md){
            color: tokenColor(set1,darkblue);
          }
        }
      }
    }
  }
  &__inner {
    background-color: tokenColor(set1, white1);
    box-shadow: 0 3px 6px  #00000026;
  }
  &__insert {
    background-color: tokenColor(set1, blue1);
    & a[class*="--label"] {
      color: tokenColor(set1, white1);
      transition: color $zaux-transition-1;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: 0;
        background-color: tokenColor(set1,orange1);
      }
      &:hover {
        color: tokenColor(set1, orange1);
        transition: color $zaux-transition-1;
      }
    }
    @include media-breakpoint-down(md) {
      &--logo1--label {
        &::after {
          right: 0;
          left: auto !important;
        }
      }
    }
  }
}

