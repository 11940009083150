@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("form");

$form-select-arrow : url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'> <path d='M5.352 21.325h5.333v5.333h-5.333zM10.685 21.325h10.643v5.333h-10.653zM21.339 21.325h5.309v5.333h-5.32zM21.339 16.005h5.309v5.32h-5.32zM21.339 10.672v-5.32h5.309v10.653h-5.32z'></path></svg>");
$form-arrow-hover : "<symbol id='icozaux1-pag-bottom-right' viewBox='0 0 32 32'><path d='M5.352 21.325h5.333v5.333h-5.333zM10.685 21.325h10.643v5.333h-10.653zM21.339 21.325h5.309v5.333h-5.32zM21.339 16.005h5.309v5.32h-5.32zM21.339 10.672v-5.32h5.309v10.653h-5.32z'></path></symbol>";
////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

@mixin form-field-theme-light1{
    border-style:none;
    appearance: none;

    &:not([type=submit]){
        background-color:transparent;
    }

    //// FIELD TYPES ////

    //Default and type 1
    &:is(input, select, textarea):not([type=submit]), &--type1, &--type1 input, &--type1 select, &--type1 textarea {

        ///// GENERIC INPUT /////
        transition: background-color $zaux-transition-0, border-color $zaux-transition-0, box-shadow $zaux-transition-0, color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
        appearance: none;
        outline:none;
        color: getCmpToken($cmp-tokens, 'color-inputtext', tokenColor(set1, darkgrey), 'theme-light1');
        border: 1px solid tokenColor(set1, darkblue);

        &:is(div){
            border-bottom:none;
        }

        &:focus{
          border-color: getCmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'darkblue'), 'theme-light1' );
        }
        &::placeholder{
            color:getCmpToken($cmp-tokens, 'color-placeholder', tokenColor(set1,grey), 'theme-light1');
        }
        &:after{
            transition: opacity $zaux-transition-0;
        }

        ///// SELECT /////

        &:is(select){

        }

        ///// RADIOS /////

        &:is(input[type=radio]){
            border-style:solid;
            border-color: getCmpToken($cmp-tokens, 'color-radioborder', tokenColor('set1', 'black1'), 'theme-light1' );
            border-radius:tokenRadius("full");
            &:after{
                background-color:getCmpToken($cmp-tokens, 'color-radiodot', tokenColor('set1','white1'), 'theme-light1');
                border-radius:tokenRadius("full");
            }
            &:focus{
                border-color: getCmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'white1'), 'theme-light1' );
                box-shadow: 0px 0px 0px 4px rgba(getCmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'white1'), 'theme-light1' ), 0.2);
            }
            &:checked{
                border-color: getCmpToken($cmp-tokens, 'color-radioborder_checked', tokenColor('set1', 'white1'), 'theme-light1' );
            }
        }

        ///// CHECKBOXES /////

        &:is(input[type=checkbox]){
            border-style:solid;
            border-color: getCmpToken($cmp-tokens, 'color-checkboxborder', tokenColor('set1', 'darkblue'), 'theme-light1' );
            border-radius:tokenRadius(4);
            &:focus{
                // border-color: getCmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', ''), 'theme-light1' );
                // box-shadow: 0px 0px 0px 3px rgba(getCmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'white1'), 'theme-light1' ), 0.2);
            }
            &:checked{
                background-color:getCmpToken($cmp-tokens, 'color-checkboxbg_focus', tokenColor('set1', darkblue), 'theme-light1');
                // border-color: getCmpToken($cmp-tokens, 'color-checkboxborder_focus', tokenColor('set1', darkblue), 'theme-light1' );
                $checkbox-checkmark-color: getCmpToken($cmp-tokens, 'color-checkmark', tokenColor('set1','white1'), 'theme-light1' );
                $checkbox-checkmark-color: str-replace(#{$checkbox-checkmark-color}, "#", "%23");
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#{$checkbox-checkmark-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
            }
          & .checkbox--label {
            color: tokenColor(set1,darkblue);
          }
        }

        &:is(input[type=file])::file-selector-button{
            appearance:none;
            font-size:inherit;
            @extend .c-btn, .c-btn--primary-light;
        }

    }

    //Type 2
    &--type2, &--type2 input, &--type2 select, &--type2 textarea{
        border-radius:tokenRadius("pill");
        border:none;
    }

    //Type 3
    &--type3, &--type3 input, &--type3 select, &--type3 textarea{
        border:none !important;
        &:not([type=submit]){
            background-color:transparent;
        }
    }

    /// LABELS ///

    &-label{
        color:getCmpToken($cmp-tokens, 'color-fieldlabel', tokenColor(set1,black1), 'theme-light1');
    }

    /// ICONS ///
    ///
    &-icon{
        color:getCmpToken($cmp-tokens, 'color-fieldicondf', tokenColor("set1","black1"), 'theme-light1');
    }

    &:is(input[type=submit]){
      transition: background-color $zaux-transition-0, color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
      background-color: tokenColor(set1,darkblue);
      color: tokenColor(set1,white);
      &:hover {
        transition: background-color $zaux-transition-0, color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
        background-color: tokenColor(set1,lightblue);
        color: tokenColor(set1,darkblue);
      }
  }
}


.#{$component}{

  &__field {
    & input, select, textarea {
      @include form-field-theme-light1;
    }
    &--checkbox-label {
      color: tokenColor(set1,darkblue);
      & a {
        text-decoration: underline;
      }
    }
}

    &__field {
        &--style1 {
          & input {
            border: 1px solid getCmpToken( $cmp-tokens, "color-fieldtype1-borderbottom", tokenColor(set1, darkblue), 'theme-light1');
          }
        }
        &--style2{
            border-radius:tokenRadius("pill");
            background-color:getCmpToken($cmp-tokens, 'color-fieldcontstyle1-bg', tokenColor("set1","white1"), 'theme-light1');
            border:getCmpToken($cmp-tokens, 'spacer-fieldwrapstyle2-borderwidth', tokenSpacer(1), 'theme-light1') solid getCmpToken($cmp-tokens, 'color-fieldwrapstyle2-border', tokenColor("set1",'white1'), 'theme-light1');
            .#{$component}__field, input, select, textarea{
                border-radius:tokenRadius("pill");
            }
        }
    }


    &__newsletter {
      &--box {
      & input[type="email"] {
        // &::placeholder {
        //   color: #00000066;
        // }
        color: #00000066;
        &:focus-visible {
          border: none;
        }
      }
      & input[type="submit"] {
        color: white;
        transition: background-color $zaux-transition-1, color $zaux-transition-1;
        background-color: tokenColor(set1,darkblue);
        &:hover {
          transition: background-color $zaux-transition-1, color $zaux-transition-1;
          background-color: tokenColor(set1,lightblue);
          color: tokenColor(set1,darkblue);
        }
      }
    }
    & a {
      color: tokenColor(set1,orange1);
    }
    }

}

