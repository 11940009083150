@charset "UTF-8";

@import "../../assets/scss/components/tokens/introsection.style";

$component: componentClass("introsection");
$cmp-tokens: ();

@if (variable-exists(zaux-infocard-style-tokens)){
    $cmp-tokens : $zaux-infocard-style-tokens;
}

.#{$component} {

  &__content {
  &--nav {
    & ul {
      display: flex;
      padding-left: 0;
      & li {
        color: #FFFFFF80;
        &.active {
          color: tokenColor(set1,white1);
          & a {
            &:hover {
              color: tokenColor(set1,white1) !important;
            }
          }
        }
      }
    }
  }
}

}
