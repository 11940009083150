@charset "UTF-8";

@import "../../assets/scss/components/tokens/newsletterbox1.style";

$component: componentClass("newsletterbox");
$cmp-tokens: ();

@if (variable-exists(zaux-newsletterbox1-style-tokens)){
    $cmp-tokens : $zaux-newsletterbox1-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.#{$component}{

     & .c-form__newsletter--box {
      background-color: white;
      }

}
