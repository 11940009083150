@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("slider");

// /swiper/modules/pagination/pagination.scss

:root {
  --swiper-theme-color: #{tokenColor("set1", "cyan1")};
  --swiper-pagination-bullet-inactive-color: #{tokenColor("set1", "gray1")};
  --swiper-pagination-bullet-horizontal-gap: #{tokenSpacer(2)};
  --swiper-pagination-bullet-vertical-gap: 0;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
}

.#{$component} {

  &__wrapper {
    overflow: hidden;
    position: relative;
  }

  &--fx-1 {
    .swiper-wrapper {
      // display: flex;
      transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
    }
  }

  &--gx-3 {
    $gx: tokenSpacer(3);

    .#{$component}__instance {
      padding-right: $gx * 2;
      > .swiper-wrapper {
        margin-left: $gx;
      }
    }
  }

  // .#{$component}__pagination{
  //     --swiper-pagination-bullet-horizontal-gap: #{getCmpToken($cmp-tokens, 'spacer-bullet-mx', tokenSpacer(1), 'layout')};
  //     --swiper-pagination-bullet-size:#{getCmpToken($cmp-tokens, 'size-bullet', 10px, 'layout')};
  // }

  &.#{$component}--lay-history {
    .swiper-navigation {
      & .swiper-button-next {
        right: 176px;
        top: 48%;
        background-color: tokenColor(set1,orange1);
        box-shadow: 0 0 2px 0 #00000080;
        & i {
          color: white;
        }
        @include media-breakpoint-down(xl) {
          right: 78px;
        }

      }
      & .swiper-button-prev {
        left: 176px;
        top:48%;
        box-shadow: 0 0 2px 0 #00000080;
        background-color: tokenColor(set1,orange1);
        & i {
          color: white;
        }
        @include media-breakpoint-down(xl) {
          left: 78px;
        }
      }
    }
  }

  .swiper-navigation {
    & .swiper-button-prev, & .swiper-button-next {
      top: 58%;
    }
    & .swiper-button-next, & .swiper-button-next2, & .swiper-button-next3 {

      border-radius: 100%;
      background-color: white;
      height: 32px;
      width: 32px;
      opacity: 1;
      // right: -16px;
      right: calc( 96px - 16px );
      &::after {
        content: '';
      }
      & i {
        font-size: 24px;
      }

    }

    & .swiper-button-prev, & .swiper-button-prev2, & .swiper-button-prev3 {
      border-radius: 100%;
      background-color: white;
      height: 32px;
      width: 32px;
      opacity: 1;
      // left: -16px;
      left: calc( 96px - 16px );
      &::after {
        content: '';
      }
      & i {
        font-size: 24px;
      }
    }

    & .swiper-button-prev2, & .swiper-button-prev3 {
      position: absolute;
      top: 50%;
      margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .swiper-button-next2, & .swiper-button-next3 {
      position: absolute;
      top: 50%;
      margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .swiper-button-disabled {
      & i {
        opacity: 0.35;
      }
    }

  @include media-breakpoint-down(md) {
    display: none;
  }

  }

  .swiper-slide{
    height:auto;
    > *{
      height:100%;
    }
  }
  .swiper-button-lock {
    display: none !important;
  }
}


