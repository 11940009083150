@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card3");

.#{$component} {
  border-radius: 4px;
  border-radius:tokenRadius("4");
  padding: 48px;
  // min-height: 224px;
  // @include media-breakpoint-down(md){
  //   min-height: 184px;
  // }

  @include media-breakpoint-down(md){
    padding: 32px;
  }
  &__name {
    word-wrap: break-word;
  }
  &__role {
    & span {
      line-height: 22px !important;
      @include media-breakpoint-down(md){
        line-height: 16px !important;
      }
    }
  }

}
