@charset "UTF-8";

@import "../../assets/scss/components/base";
@import "../../assets/scss/components/tokens/hero.style";

$component: componentClass("hero");
$cmp-tokens: ();

@if ($zaux-hero-style-tokens){
    $cmp-tokens : $zaux-hero-style-tokens;
}

.#{$component} {
  margin-top: 120px;
  @include media-breakpoint-down(md){
    margin-top: 72px;
  }
  &__inner {
    height: calc( 100vh - 120px );
    @include media-breakpoint-down(md) {
      height: calc( 100vh - 72px );
    }
  }

  &__content {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & h2 {
      color: transparent !important;
      -webkit-text-stroke: 1px tokenColor(set1, lightblue);
    }
  }

}

