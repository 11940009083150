.wpcf7-not-valid-tip{
    padding-top:tokenSpacer(2);
}

.wpcf7-response-output{
    border:none !important;
    padding: tokenSpacer(3) !important;
    background-color: tokenColor("default", "warning");
    color: tokenColor("set1","white1") !important;
}
