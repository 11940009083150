@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card3");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {

  &--theme-light {
  color: tokenColor(set1, darkblue);
  border: 1px solid #002D74;
}

&--theme-dark {
  color: tokenColor(set1, white1);
  border: 1px solid white;
}

}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

