@charset "UTF-8";

@import "../../assets/scss/components/tokens/displayblock1.style";

$component: componentClass("displayblock3");
$cmp-tokens: ();

@if (variable-exists(zaux-displayblock2-style-tokens)){
    $cmp-tokens : $zaux-displayblock2-style-tokens;
}

.#{$component} {

  &__content {
    &--img {

      text-align: center;
      // height: 176px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      & img {
        max-width: 100%;
        max-height: 100%;
        min-width: 96px;
        width: auto;
        height: auto;
        min-height: 48px;
        object-fit: contain;
        object-position: center center;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        padding: 32px;
      }
    }

  }
}
