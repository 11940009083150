@charset "UTF-8";

$zaux-helper-name: "color";

////////////////////////////////////////////////////
// HELPER MIXINS
////////////////////////////////////////////////////
@mixin zaux-helper-color($name, $color) {
  .zaux-color-#{$name} {
    color: $color !important;
  }
}

@mixin zaux-helper-bgcolor($name, $color) {
  .zaux-bg-#{$name} {
    background-color: $color !important;
  }
}

@mixin zaux-bg-opacity-color($name, $color, $opacity, $radius : 0, $blend : "normal" ) {
  .#{$color}-bg-#{$name} {
    &::after {
      mix-blend-mode: $blend;
      position: absolute;
      top: 0;
      left: 0;
      content: " ";
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba(tokenColor('set1', $color ), $opacity );
      border-radius: $radius;
    }
  }
}

////////////////////////////////////////////////////
// HELPER CLASSES
////////////////////////////////////////////////////

@each $setName, $setObj in tokenColors() {
  @each $colorName, $colorVal in $setObj {
    @include zaux-helper-color("#{$setName}-#{$colorName}", $colorVal);
  }
}

@each $setName, $setObj in tokenColors() {
  @each $colorName, $colorVal in $setObj {
    @include zaux-helper-bgcolor("#{$setName}-#{$colorName}", $colorVal);
  }
}

////////////////////////////////////////////////////
// HELPER CSS VARIABLES
////////////////////////////////////////////////////

// :root {
//   --#{tokenDef('css-var-prefix')}-test: #f00;
// }

.darkblue-bg-20 {
  &::after {
    mix-blend-mode: normal;
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background-color: #002D7433;
    border-radius: 4px;
  }
}

.darkblue-bg-25 {
  &::after {
    mix-blend-mode: normal;
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background-color: #002D7440;
    border-radius: 2px;
  }
}

.darkblue-bg-45 {
  &::after {
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background-color: #002D7473;
    border-radius: 4px;
  }
}

.darkblue-bg-85 {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background-color: #002D74D9;;
  }
}
.darkblue-bg-multiply-85 {
  &::after {
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background-color: #002D74D9;;
  }
}
.darkblue-bg-75 {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background-color: #002D74BF;
  }
}
.darkblue-bg-65 {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background-color: #002D74A6;
  }
}
.blue-bg-80 {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background-color: #005AAACC;
  }
}

.gradient-blue1 {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to bottom, #B9CFEC00 , #163475F5 90%, #0F2E70 100% );
  }
}
.gradient-blue2 {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to bottom, #002D7400 , #265AA4 );
  }
}

