@charset "UTF-8";

// @import "../../assets/scss/components/tokens/showcase1.style";

$component: componentClass("sectionroster");
$cmp-tokens: ();

// @if ($zaux-showcase1-style-tokens){
//     $cmp-tokens : $zaux-showcase1-style-tokens;
// }

.#{$component} {

  &__tab {
    &--item {
      @include media-breakpoint-down(md){
        font-size: 16px !important;
      }
    }
  }
  &__slider {
    display: none;
    &.open {
      display: block;
    }
  }
}
