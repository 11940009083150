@charset "UTF-8";

//////////////////////////////////////////////////
// MISC - WIDTHS
//////////////////////////////////////////////////

.w-80{
    width:80%;
}
.w-75{
    width:75%;
}

.w-60{
    width:60%;
}

.w-50{
    width:50%;
}
.w-75{
    width:75%;
}
.w-60{
    width:60%;
}
.w-40{
    width:40%;
}
.w-30{
    width:30%;
}
.w-25{
    width:25%;
}

@each $k in tokenTypoBreakpoints() {
    @include media-breakpoint-up(#{$k}) {
        .w-#{$k}-80{
            width:80%;
        }
        .w-#{$k}-75{
            width:75%;
        }
        .w-#{$k}-60{
            width:60%;
        }
        .w-#{$k}-50{
            width:50%;
        }
        .w-#{$k}-40{
            width:40%;
        }
        .w-#{$k}-30{
            width:30%;
        }
        .w-#{$k}-25{
            width:25%;
        }
    }
}

.text-left{
    text-align:left;
}

.text-center{
    text-align:center;
}

.text-right{
    text-align:right;
}

@each $k in tokenTypoBreakpoints() {
    @include media-breakpoint-up(#{$k}) {
        .text-#{$k}-left{
            text-align:left;
        }
        .text-#{$k}-center{
            text-align:center;
        }
        .text-#{$k}-right{
            text-align:right;
        }
    }
}

.zaux-zi-2 {
  z-index: 2;
}

.deco-underline {
position: relative;
&::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: tokenColor(set1,orange1);
}
}


.fancybox-nav span {
  visibility: visible !important;
}
.fancybox-next span {
  right: -35px !important;
}
.fancybox-prev span {
  left: -35px !important;
}
.fancybox-infobar {
  left: 50% !important;
  transform: translateX(-50%);
  font-size: 24px !important;
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}
.fancybox-content {
  max-width: 1920px !important;
  max-height: 1080px !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.fancybox-caption__body {
  font-size: 32px;
  font-family: $zaux-font2;
  font-weight: 500;
  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
}
// .fancybox-image {
//   height: auto !important;
//   width: auto !important;
//   height: auto !important;
//   left: 50% !important;
//   top: 50% !important;
//   transform: translate(-50%, -50%);
//   max-width: 1920px !important;
//   max-height: 1080px !important;
// }
// .fancybox-iframe {
//   left: 50% !important;
//   top: 50% !important;
//   transform: translate(-50%, -50%);
//   max-width: 1920px !important;
//   max-height: 1080px !important;
// }

