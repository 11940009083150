@charset "UTF-8";

// @import "../../assets/scss/components/tokens/sectionroster.style";

$component: componentClass("sectionroster");
$cmp-tokens: ();

// @if (variable-exists(zaux-displayblock1-style-tokens)){
//     $cmp-tokens : $zaux-displayblock1-style-tokens;
// }

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


 .#{$component}{

  &__bg-img {
    @include zaux-bg-opacity-color(87, darkblue, 0.87, 0, multiply );
  }

  &__tab {
    &--item {
      &.active {
        & span {
          color: tokenColor(set1, white1 );
        }
      }
      & span {
        color: #FFFFFF80;
      }
    }
  }


}
