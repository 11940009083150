@charset "UTF-8";

@import "../../assets/scss/components/tokens/footer.style";

$component: componentClass("footer");
$cmp-tokens: ();

@if ($zaux-footer-style-tokens){
    $cmp-tokens : $zaux-footer-style-tokens;
}

.#{$component} {

    &__row{
      max-width: 1288px;
      margin: auto;
      z-index: 2;
    }

    &__logo {
      max-width:100%;
      text-align: center;
      transform: translateY(-126px);
      @include media-breakpoint-down(md){
        transform: translateY(-108px);
        & img {
          height: 168px;
          width: auto;
        }
      }

  }

  &__menu {
    margin-top: -64px;
    @include media-breakpoint-down(md){
      margin-top: -72px;
      & .col-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &--col {
      &--label {
        padding-bottom: 16px;
        display: inline-block;
      }
      &--item {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      @include media-breakpoint-down(md) {
        text-align: center;
        &--label {
          padding-bottom: 0;
        }
        &--show {
          display: none;
        }
        &.open {
          & .#{$component}__menu--col--label {
            padding-bottom: 12px;
          }
          & .#{$component}__menu--col--show {
            display: block;
            border-top: 3px solid tokenColor(set1,orange1);
            padding: 24px;
          }
        }
      }
      &--icon {
        font-size: 8px;
        display: none;
        margin-left: 8px;
        vertical-align: middle;
        @include media-breakpoint-down(md){
          display: inline;
        }
      }
      &--item--icon {
        font-size: 12px;
        display: none;
        margin-right: 8px;
        vertical-align: middle;
        @include media-breakpoint-down(md){
          display: inline;
        }
      }
    }
  }

  &__info {
    @include media-breakpoint-down(md){
      text-align: center;
    }
  }

    &__social{
        list-style-type:none;
        &-item{
            display:inline-block;
            margin-left: 24px;
            &:first-child {
              margin-left: 0;
            }
        }
        .#{$component-prefix}icon{
           font-size:tokenIconSize(4);
        }
    }

    &__credits{
      @include media-breakpoint-up(lg) {
        padding-top: getCmpToken($cmp-tokens, 'spacer-credits-py', tokenSpacer(4), 'layout');
        padding-bottom: getCmpToken($cmp-tokens, 'spacer-credits-py', tokenSpacer(4), 'layout');
        &-icon {
          position: absolute;
          right: 16px;
          top: 0;
        }
      }
        font-size: getCmpToken($cmp-tokens, 'fs-credits', tokenTypoSizeFs(6), 'layout');
        font-weight: 300;
      &--inner {
        @include media-breakpoint-down(lg){
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

}
