@charset "UTF-8";

@import "../../assets/scss/components/tokens/textblock.style";

$component: componentClass("textblock");
$cmp-tokens: ();

@if (variable-exists(zaux-textblock-style-tokens)){
    $cmp-tokens : $zaux-textblock-style-tokens;
}

.#{$component} {

  & p img {
    max-width: 100%;
  }
  &__text {
    & a {
      text-decoration: underline;
      text-decoration-color: #F6A100;
    }
  }
  &__contacts {
    &--list {
      & ul {
          & li {
            font-weight: 500;
            font-family: $zaux-font2;
            margin-bottom: 64px;
            @include media-breakpoint-down(md){
              margin-bottom: 48px;
            }
            & .#{$component}__contacts--list--item {
              text-transform: uppercase;
              letter-spacing: 0.008em;
              font-size: 16px;
              line-height: 24px;
              font-family: $zaux-font1;
              font-weight: 600;
              padding-bottom: 12px;
              // white-space: nowrap;
            }
            & span:not(.c-textblock__contacts--list--item ) {
              display: block;
            }
          }
        }
    }
    &--social {
      & ul {
          list-style-type: none;
          & li {
            display: inline-block;
            margin-right: 24px;
            margin-bottom: 0 !important;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
  }

}
