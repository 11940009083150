@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card4");

.#{$component} {
  border-radius: 4px;
  &__inner {
    padding: 80px 40px 48px 40px;
    @include media-breakpoint-down(md){
      padding: 48px 24px 32px 24px;
    }
  }
  &__text {
    & p {
      font-weight: 300;
    }
  }

}
