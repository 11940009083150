@charset "UTF-8";

@import "../../assets/scss/components/tokens/header.style";

$component: componentClass("header");
$cmp-tokens: ();

@if (variable-exists(zaux-header-style-tokens)){
    $cmp-tokens : $zaux-header-style-tokens;
}

.#{$component} {

  &__match {
    height: 62px;
    @include media-breakpoint-down(md) {
      height: 48px;
      margin-top: 48px; //Primo il banner con calendario e campionati
    }
    &--social {
      &--item {
        margin-left: 24px;
        @include media-breakpoint-down(md) {
          margin-left: 16px;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &--data {
      & i {
        font-size: 24px;
        @include media-breakpoint-down(md){
          font-size: 16px;
        }
      }
    }
  }

    &__inner {
      height: 120px;
      position: fixed;
      top: 62px; //Banner partita
      left: 0;
      z-index:tokenZindex(header);
      width: 100%;
      @include media-breakpoint-down(md) {
      height: 72px;
      top: calc( 48px + 48px ) ; //Somma dei due banner
    }
    }

  &__logo {
    opacity: 0;
    // transition: opacity $zaux-transition-1;
    &.scroll-down {
      opacity: 1;
      transition: opacity $zaux-transition-1;
    }
    & img {
      @include media-breakpoint-down(md){
        width: 86px;
        height: auto;
      }
    }
    &--full {
      top: 24px;
      @include media-breakpoint-down(md){
        top: 16px;
      }
      & img {
        @include media-breakpoint-down(md){
          width: 96px;
          height: auto;
        }
      }
      &--menu {
        display: none !important;
      }
    }

  }

  &__infobtn {
    display: block;
    @include media-breakpoint-down(lg) {
      transform: translateX(-96px);
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__nav {
    @include media-breakpoint-up(lg) {
      height: 68px;
      position: fixed;
      // top: 52px ;
      top: 114px ;
      width: 100%;
      z-index: 105;
      display: flex;
      justify-content: center;
      max-width: 768px;
      left: 50%;
      transform: translateX(-50%);
    }
    @include media-breakpoint-up(xl) {
      max-width: 912px;
    }

    & .c-menu {
      @include media-breakpoint-up(lg) {
        padding-left: 8px;
        padding-right: 8px;
      }
      @include media-breakpoint-up(xl) {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  & .c-menu__btn-toggle {
    & i {
      vertical-align: text-top;
    }
    & .c-icon-menu-close {
      display: none;
      height: 16px;
      width: 16px;
    }
    &--close {
      display: none;
    }
    &.is-active {
      & .c-icon-menu-close {
        display: inline-block;
      }
      & .c-icon-menu-open {
        display: none;
      }
      & .c-menu__btn-toggle--menu {
        display: none;
      }
      & .c-menu__btn-toggle--close {
        display: inline-block;
      }
    }
  }

  &__btn--menu {
    display: none;
    position: absolute;
    right: 0;
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }

  &__insert {
    position: absolute;
    right: 0;
    top: 182px;
    border-bottom-left-radius: 90px;
    height: 72px;
    padding-left: 72px;
    padding-right: 96px;
    z-index: 3;
    @include media-breakpoint-down(md){
      top: 0;
      padding: 0 16px;
      border-bottom-left-radius: 0;
      height: 48px;
      left: 0;
      &--logo1 {
        &--label {
          text-align: end;
        }
      }
    }
  }

}

.zaux-menu-active .c-header__logo.scroll-down {
  opacity: 0;
}

.zaux-menu-active .c-header__logo--full {
  &--base {
    display: none !important;
  }
  &--menu {
    display: block !important;
  }
}

@media screen and (max-width: 492px) {
  [class*="__insert--logo"] span {
    display: block;
  }
}
@media screen and (min-width: 1660px) {
  .c-header__nav {
    max-width: 992px;
  }
}
