@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("form");

@mixin form-fields-rules1 {
    &:focus{
        outline:none;
    }

    input{
        &:focus{
            outline:none;
        }
    }

    //Sizes

    //Small is also default
    &--size-s,
    &:not([class*=--size]),
    &:is(input, select, textarea):not([class*=--size]):not([type=submit]){
        font-size:getCmpToken($cmp-tokens, 'fs-inputsmall', tokenTypoSizeFs(6), 'layout');
        // line-height:getCmpToken($cmp-tokens, 'lh-inputsmall', tokenTypoSizeLh(3),'layout');
        line-height: 66px;
        letter-spacing: 0.008em;
        & + .#{$component}__field-icon{
            font-size: tokenIconSize(3);
        }
        padding: 0 24px;
        border-radius: 4px;
        width: 100%;
    }

    //Medium
    &--size-m{
        padding: getCmpToken($cmp-tokens, 'spacer-inputmedium-py', tokenSpacer(3), 'layout') getCmpToken($cmp-tokens, 'spacer-inputmedium-px', tokenSpacer(0), 'layout');
        font-size:getCmpToken($cmp-tokens, 'fs-inputmedium', tokenTypoSizeFs(6), 'layout');
        line-height:getCmpToken($cmp-tokens, 'lh-inputmedium', tokenTypoSizeLh(6),'layout');
        input{
            padding: getCmpToken($cmp-tokens, 'spacer-inputmedium-py', tokenSpacer(3), 'layout') getCmpToken($cmp-tokens, 'spacer-inputmedium-px', tokenSpacer(0), 'layout');
        }
        & + .#{$component}__field-icon{
            font-size: tokenIconSize(4);
        }
        border-radius:tokenRadius("4");
    }

    //Large
    &--size-l{
        padding: getCmpToken($cmp-tokens, 'spacer-inputlarge-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-inputlarge-px', tokenSpacer(0), 'layout');
        font-size:getCmpToken($cmp-tokens, 'fs-inputlarge', tokenTypoSizeFs(9), 'layout');
        line-height:getCmpToken($cmp-tokens, 'lh-inputlarge', tokenTypoSizeLh(9),'layout');
        input{
            padding: getCmpToken($cmp-tokens, 'spacer-inputlarge-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-inputlarge-px', tokenSpacer(0), 'layout');
        }
        & + .#{$component}__field-icon{
            font-size: tokenIconSize(5);
        }
        border-radius:tokenRadius("4");
    }

    //Radios
    &:is([type=radio]){
        position:relative;
        padding:0 !important;
        &,&.#{$component}__field--size-s{
            width:20px;
            height:20px;
            border-width: getCmpToken($cmp-tokens, 'css-radioinput-borderwidth', 1px, 'layout') !important;
        }
        &:after{
            content:"";
            position:absolute;
            pointer-events:none;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            height:10px;
            width:10px;
            opacity:0;
        }
        &:checked{
            &:after{
                opacity:1;
            }
        }
        &.#{$component}__field--size-m{
            width:30px;
            height:30px;
            padding:0;
            border-width: getCmpToken($cmp-tokens, 'css-radioinputmedium-borderwidth', 1px, 'layout') !important;
            &:after{
                height:15px;
                width:15px;
            }
        }
        &.#{$component}__field--size-l{
            width:40px;
            height:40px;
            padding:0;
            border-width: getCmpToken($cmp-tokens, 'css-radioinputlarge-borderwidth', 1px, 'layout') !important;
            &:after{
                height:20px;
                width:20px;
            }
        }
    }

    //Checkboxes
    &:is([type=checkbox]){
        position:relative;
        padding:0 !important;
        &, &.#{$component}__field--checkbox-s {
            width:24px !important;
            height:24px;
            padding:0;
            border-width: getCmpToken($cmp-tokens, 'css-radioinput-borderwidth', 1px, 'layout') !important;
        }
        &.#{$component}__field--checkbox-m {
            width:30px;
            height:30px;
            padding:0;
            border-width: getCmpToken($cmp-tokens, 'css-radioinputmedium-borderwidth', 1px, 'layout') !important;
        }
        &.#{$component}__field--checkbox-l {
            width:40px;
            height:40px;
            padding:0;
            border-width: getCmpToken($cmp-tokens, 'css-radioinputlarge-borderwidth', 1px, 'layout') !important;
        }
    }

    //File
    &:is([type=file])::file-selector-button {
        margin-right:tokenSpacer(2);
        appearance:none;
        border-style:none;
        //padding: tokenSpacer(1) tokenSpacer(2);
    }

    //Variants
    &--type2, &--type3{
        border:none;
        &.#{$component}__field{
            &--size-s{
                padding: getCmpToken($cmp-tokens, 'spacer-input2small-py', tokenSpacer(2), 'layout') getCmpToken($cmp-tokens, 'spacer-input2small-px', tokenSpacer(3), 'layout');
                font-size:getCmpToken($cmp-tokens, 'fs-inputsmall', tokenTypoSizeFs(3)+1, 'layout');
                input{
                    padding: getCmpToken($cmp-tokens, 'spacer-input2small-py', tokenSpacer(2), 'layout') getCmpToken($cmp-tokens, 'spacer-input2small-px', tokenSpacer(3), 'layout');
                }
            }
            &--size-m{
                padding: getCmpToken($cmp-tokens, 'spacer-input2medium-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-input2medium-px', tokenSpacer(5), 'layout');
                font-size:getCmpToken($cmp-tokens, 'fs-inputmedium', tokenTypoSizeFs(6), 'layout');
                input{
                    padding: getCmpToken($cmp-tokens, 'spacer-input2medium-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-input2medium-px', tokenSpacer(5), 'layout');
                }
            }
            &--size-l{
                padding: getCmpToken($cmp-tokens, 'spacer-input2large-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-input2large-px', tokenSpacer(5), 'layout');
                font-size:getCmpToken($cmp-tokens, 'fs-inputlarge', tokenTypoSizeFs(9), 'layout');
                input{
                    padding: getCmpToken($cmp-tokens, 'spacer-input2medium-py', tokenSpacer(2), 'layout') getCmpToken($cmp-tokens, 'spacer-input2medium-px', tokenSpacer(5), 'layout');
                }
            }
        }
    }

    &:is(div){
        padding:0 !important;
    }
}


.#{$component} {



    &__field {
      & input, select, textarea {
        @include form-fields-rules1;
      }
      & textarea {
          line-height: 24px !important;
          min-height: 140px;
          padding: 24px !important;
      }
      & input[type=submit]{
        @extend .c-btn1;
        max-width: 130px;
        line-height: 57px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
    }
    }

    &__newsletter {
      &--box {
      border-radius: 4px;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      & input[type="email"] {
        line-height: 45px;
        font-size: 16px;
        width: 100%;
        margin-left: 25px;
        border: none;
        outline: none;
      }
      & input[type="submit"] {
        border-radius: 2px;
        line-height: 45px;
        min-width: 130px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.050em;
        border: none;
      }

    }
    & .wpcf7-form-control-wrap {
      position: unset;
    }

  }

}

// Contact form
.c-form {
  .wpcf7-list-item {
    margin: 0;
  }
}
